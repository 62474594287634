import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  Pagination,
  PushNotification,
  PushNotificationCountData,
} from '@/helpers/types';
import { PaginatePushNotification } from '@/api/pushNotification/paginatePushNotification';
import { GetPushNotificationCount } from '@/api/pushNotification/getPushNotificationCount';

// Define the type for the API response
interface PushNotificationResponse {
  data: PushNotification[];
  pagination: Pagination;
}

interface PushNotificationCountResponse {
  data: PushNotificationCountData;
}

type FetchPushNotificationError = string | SerializedError;
type PaginatePushNotificationArgs = {
  pageSize: string;
  pagination: number;
};

export const paginatePushNotifications = createAsyncThunk<
  PushNotificationResponse,
  PaginatePushNotificationArgs,
  {
    rejectValue: FetchPushNotificationError;
  }
>(
  'pushNotification/paginatePushNotifications',
  async ({ pageSize, pagination }, { rejectWithValue }) => {
    // Destructure the argument
    try {
      const response = await PaginatePushNotification(pageSize, pagination); // Pass both parameters
      const data: PushNotificationResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchPushNotificationCount = createAsyncThunk<
  PushNotificationCountResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchPushNotificationError; // Type for rejected value
  }
>(
  'pushNotification/fetchPushNotificationCount',
  async (_, { rejectWithValue }) => {
    try {
      const data: PushNotificationCountResponse =
        await GetPushNotificationCount();
      return { data: data.data }; // Return formatted data
    } catch (error) {
      // Type guard to narrow down error to Error type
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      // Fallback for unknown error types
      return rejectWithValue('An unknown error occurred');
    }
  },
);
