import { API_INVOICE, API_METHOD_POST } from '@/constants/apiConstants';
import { InvoicePaymentData } from '@/redux/thunk/invoicePaymentThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const AddInvoicePayment = async (
  newInvoicePaymentData: InvoicePaymentData,
) => {
  const method: Method = API_METHOD_POST;
  const url =
    API_INVOICE + '/' + newInvoicePaymentData.invoiceUuid + '/payments';
  const data = {
    method: newInvoicePaymentData.method,
    amount: newInvoicePaymentData.amount,
    date: newInvoicePaymentData.date,
    reference: newInvoicePaymentData.reference,
    details: newInvoicePaymentData.details,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
