import {
  EnquiriesData,
  EnquiryData,
  EnquiryNoteData,
  EnquiryNoteType,
  EnquiryType,
  Pagination,
} from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addEnquiry,
  addEnquiryNote,
  fetchEnquiries,
  fetchEnquiry,
  fetchEnquiryNote,
  fetchEnquiryNotes,
  searchEnquiry,
} from '../thunk/enquiryThunk';

interface EnquiryState {
  // enquiry: EnquiryType | null;
  // enquiries: EnquiryType[];
  enquiry: EnquiryData | null;
  enquiries: EnquiryData[];
  enquiryNote: EnquiryNoteData | null;
  enquiryNotes: EnquiryNoteData[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: EnquiryState = {
  enquiry: null,
  enquiries: [],
  enquiryNote: null,
  enquiryNotes: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const enquirySlice = createSlice({
  name: 'enquiry',
  initialState,
  reducers: {
    clearEnquiries(state) {
      state.enquiry = initialState.enquiry;
      state.enquiries = initialState.enquiries;
      state.enquiryNote = initialState.enquiryNote;
      state.enquiryNotes = initialState.enquiryNotes;
      state.pagination = initialState.pagination;
      state.status = initialState.status;
      state.error = initialState.error;
      state.fieldErrors = initialState.fieldErrors;
    },
    clearEnquiry(state) {
      state.enquiry = initialState.enquiry;
    },
    clearEnquiryNote(state) {
      state.enquiryNote = initialState.enquiryNote;
    },
  },
  extraReducers: (builder) => {
    // Add enquiry
    builder
      .addCase(addEnquiry.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        addEnquiry.fulfilled,
        (state, action: PayloadAction<EnquiryType>) => {
          state.status = 'succeeded';
          state.enquiries.push(action.payload);
          state.error = null;
          state.fieldErrors = {};
        },
      )
      .addCase(addEnquiry.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload.message;
          state.fieldErrors = action.payload.messages || {};
        } else {
          state.error = 'An unknown error occurred';
        }
      });

    // Fetch enquiries
    builder
      .addCase(fetchEnquiries.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEnquiries.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: EnquiriesData;
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.enquiries = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(fetchEnquiries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Search enquiries
    builder
      .addCase(searchEnquiry.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchEnquiry.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: EnquiriesData;
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.enquiries = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(searchEnquiry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Fetch enquiry
    builder
      .addCase(fetchEnquiry.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEnquiry.fulfilled,
        (state, action: PayloadAction<EnquiryData>) => {
          state.status = 'succeeded';
          state.enquiry = action.payload;
        },
      )
      .addCase(fetchEnquiry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Add enquiry note
    builder
      .addCase(addEnquiryNote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        addEnquiryNote.fulfilled,
        (state, action: PayloadAction<EnquiryNoteType>) => {
          state.status = 'succeeded';
          state.enquiryNotes.push(action.payload);
          state.error = null;
          state.fieldErrors = {};
        },
      )
      .addCase(addEnquiryNote.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload.message;
          state.fieldErrors = action.payload.messages || {};
        } else {
          state.error = 'An unknown error occurred';
        }
      });

    // Fetch enquiry note
    builder
      .addCase(fetchEnquiryNote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEnquiryNote.fulfilled,
        (state, action: PayloadAction<EnquiryNoteData>) => {
          state.status = 'succeeded';
          state.enquiryNote = action.payload;
        },
      )
      .addCase(fetchEnquiryNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearEnquiries, clearEnquiry, clearEnquiryNote } =
  enquirySlice.actions;
export default enquirySlice.reducer;
