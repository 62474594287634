import { API_INVOICE, API_METHOD_PUT } from '@/constants/apiConstants';
import { UpdateInvoicePaymentData } from '@/redux/thunk/invoicePaymentThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateInvoicePayment = async (
  updatedInvoicePaymentData: UpdateInvoicePaymentData,
) => {
  const method: Method = API_METHOD_PUT;
  const url =
    API_INVOICE +
    '/' +
    updatedInvoicePaymentData.invoiceUuid +
    '/payments/' +
    updatedInvoicePaymentData.paymentUuid;
  const data = {
    method: updatedInvoicePaymentData.method,
    amount: updatedInvoicePaymentData.amount,
    date: updatedInvoicePaymentData.date,
    reference: updatedInvoicePaymentData.reference,
    details: updatedInvoicePaymentData.details,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
