'use client';
import React from 'react';

import useRouteLoading from '@/hooks/useRouteLoading';
import Loading from '@/components/loading/Loading';

const RouteLoader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isPending = useRouteLoading();

  return isPending ? <Loading /> : <>{children}</>;
};

export default RouteLoader;
