import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Subscription } from '@/helpers/types';
import { GetSubscriptionDetails } from '@/api/accountBilling/getSubscriptionDetails';

// Define the type for the API response
interface SubscriptionResponse {
  data: Subscription;
}
export const fetchSubscription = createAsyncThunk<
  SubscriptionResponse,
  void,
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>('subscription/fetchSubscription', async (_, { rejectWithValue }) => {
  try {
    return await GetSubscriptionDetails();
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
