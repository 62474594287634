import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleJob } from '@/helpers/types';
import { fetchJobByUuid } from '../thunk/jobThunk';

interface JobState {
  singleJob: SingleJob;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: JobState = {
  singleJob: {
    uuid: '',
    title: '',
    type: 0,
    job_number: '',
    company: {
      id: '',
      name: ''
    },
    customer: {
      id: '',
      name: '',
      email: '',
      phone: '',
      address: ''
    },
    customer_site: {
      id: '',
      name: ''
    },
    related_quote: {
      id: null,
      title: null,
      quote_number: null
    },
    related_recurring_job: {
      id: null,
      title: null,
      job_number: null
    },
    details: null,
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    recurring_type: null,
    recurring_data: undefined,
    total_price: 0,
    total_product_cost: 0,
    total_labour_cost: 0,
    total_expenses: 0,
    total_profit: 0,
    margin: 0,
    status: 0,
    assigned_members: [],
    job_notes: [],
    job_products: [],
    job_expenses: [],
    job_timesheets: [],
    job_photos: [],
    job_forms: []
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    clearJob(state) {
      state.singleJob = initialState.singleJob;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchJobs (existing functionality)
    builder
      .addCase(fetchJobByUuid.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobByUuid.fulfilled,
        (
          state,
          action: PayloadAction<{ data: SingleJob }>,
        ) => {
          state.singleJob = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchJobByUuid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearJob } = jobSlice.actions;
export default jobSlice.reducer;
