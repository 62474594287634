import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination } from '@/helpers/types';
import {
  createQuote,
  deleteQuote,
  fetchQuotes,
  searchQuotes,
  updateQuote,
} from '../thunk/quoteThunk';
import { Quote, SingleQuoteByUuid } from '@/helpers/types/quoteTypes';

interface QuoteState {
  quotes: Quote[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: QuoteState = {
  quotes: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    clearQuotes(state) {
      state.pagination = initialState.pagination;
      state.quotes = initialState.quotes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchQuotes.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Quote[]; pagination: Pagination }>,
        ) => {
          state.quotes = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchQuotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // search quotes
      .addCase(searchQuotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchQuotes.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Quote[]; pagination: Pagination }>,
        ) => {
          state.quotes = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchQuotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // Delete quote cases
      .addCase(deleteQuote.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteQuote.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteQuote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // Handle create quote
      .addCase(createQuote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(createQuote.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.error = null; // Clear any previous errors
        state.fieldErrors = {}; // Clear field errors on success
      })
      .addCase(createQuote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // Handle update quote
      .addCase(updateQuote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(updateQuote.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.error = null; // Clear any previous errors
        state.fieldErrors = {}; // Clear field errors on success
      })
      .addCase(updateQuote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearQuotes } = quoteSlice.actions;
export default quoteSlice.reducer;
