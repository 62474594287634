import { API_INVOICE, API_METHOD_PUT } from '@/constants/apiConstants';
import { UpdatedInvoiceFormData } from '@/redux/thunk/invoiceThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateInvoice = async (
  updatedInvoiceData: UpdatedInvoiceFormData,
) => {
  const method: Method = API_METHOD_PUT;
  const url = API_INVOICE + '/' + updatedInvoiceData.invoiceUuid;
  const data = {
    status: updatedInvoiceData.status,
    job_id: updatedInvoiceData.job_id,
    customer_id: updatedInvoiceData.customer_id,
    invoice_number: updatedInvoiceData.invoice_number,
    title: updatedInvoiceData.title,
    issued_date: updatedInvoiceData.issued_date,
    payment_due: updatedInvoiceData.payment_due,
    customer_message: updatedInvoiceData.customer_message,
    tax_rate: updatedInvoiceData.tax_rate,
    tax_rate_id: updatedInvoiceData.tax_rate_id,
    discount: updatedInvoiceData.discount,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
