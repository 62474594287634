import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Job } from '@/helpers/types';
import { fetchJobs, searchJobs } from '@/redux/thunk/jobScheduleThunk';

interface JobState {
  jobs: Job[];
  // pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: JobState = {
  jobs: [], // Initialize as null for a single team member
  // pagination: {
  //   total_records: 0,
  //   current_records: 0,
  //   record_from: 0,
  //   record_to: 0,
  //   current_page: 0,
  //   total_pages: 0,
  // },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const jobScheduleSlice = createSlice({
  name: 'jobSchedule',
  initialState,
  reducers: {
    clearJob(state) {
      // state.pagination = initialState.pagination;
      state.jobs = initialState.jobs;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchJobs (existing functionality)
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobs.fulfilled,
        (state, action: PayloadAction<{ data: Job[] }>) => {
          state.jobs = action.payload.data;
          // state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    //Handle searchTeamMembers (existing functionality)
    builder
      .addCase(searchJobs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchJobs.fulfilled,
        (state, action: PayloadAction<{ data: Job[] }>) => {
          state.jobs = action.payload.data;
          // state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearJob } = jobScheduleSlice.actions;
export default jobScheduleSlice.reducer;
