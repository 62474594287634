import { API_ENQUIRY, API_METHOD_DELETE } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const DeleteEnquiryNote = async (
  enquiryUuid: string,
  noteUuid: string,
) => {
  const method: Method = API_METHOD_DELETE;
  const url = API_ENQUIRY + '/' + `${enquiryUuid}` + '/notes/' + `${noteUuid}`;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
