import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
// Import your reducers here
import authReducer from './slices/authSlice';
import teamReducer from './slices/teamSlice';
import teamStatusReducer from './slices/teamMemberStatus';
import teamAllReducer from './slices/teamAllSlice';
import quoteStatusReducer from './slices/quoteStatus';
import customerReducer from './slices/customersSlice';
import customerSitesReducer from './slices/customerSiteSlices';
import breadcrumbPathReducer from './slices/breadcrumbSlice';
import customerStatusReducer from './slices/customerStatus';
import productReducer from './slices/productSlice';
import quoteReducer from './slices/quoteSlice';
import timesheetReducer from './slices/timesheetSlice';
import expenseReducer from '@/redux/slices/expensesSlice';
import expenseMetricsReducer from '@/redux/slices/expensesMetricsSlice';
import customerTitlesReducer from '@/redux/slices/customerTitlesSlice';
import singleQuoteReducer from '@/redux/slices/singleQuoteSlice';
import financesReducer from '@/redux/slices/calculateFinancesSlice';
import quoteNotesReducer from '@/redux/slices/quoteNoteSlice';
import timesheetCategoryReducer from '@/redux/slices/timesheetCategorySlice';
import timesheetMetricsReducer from '@/redux/slices/timesheetMetricsSlice';
import jobAllReducer from '@/redux/slices/jobAllSlice';
import jobsReducer from '@/redux/slices/jobSlice';
import jobStatusReducer from './slices/jobStatusSlice';
import jobTypeReducer from './slices/jobTypeSlice';
import jobScheduleReducer from './slices/jobScheduleSlice';
import SelectedJobDataReducer from './slices/SinglejobSlice';
import jobRecurringTypeReducer from './slices/jobRecurringTypeSlice';
import enquiryReducer from './slices/enquirySlice';
import enquiryStatusReducer from './slices/enquiryStatusSlice';
import exportReducer from './slices/exportSlice';
import jobNotesReducer from '@/redux/slices/jobNoteSlice';
import customerErrorReducer from '@/redux/slices/customerErrorSlice';
import productErrorReducer from '@/redux/slices/productErrorSlice';
import accountBillingReducer from '@/redux/slices/accountBillingSlice';
import pushNotificationCountReducer from '@/redux/slices/pushNotificationCountSlice';
import pushNotificationReducer from '@/redux/slices/pushNotificationSlice';
import invoicesReducer from './slices/invoiceAllSlice';
import invoiceAllStatusReducer from './slices/invoiceAllStatusSlice';
import invoiceReducer from './slices/invoiceSingleSlice';
import invoicePaymentMethodsReducer from './slices/invoicePaymentMethodsAllSlice';
import invoiceAllPaymentReducer from './slices/invoiceAllPaymentSlice';
import invoiceSinglePaymentReducer from './slices/invoiceSinglePaymentSlice';
import invoiceAllNotesReducer from './slices/invoiceAllNoteSlice';
import invoiceSingleNoteReducer from './slices/invoiceSingleNote';
import invoiceAllSignaturesReducer from './slices/invoiceAllSignaturesSlice';

const persistConfig = {
  key: 'root', // Key for localStorage
  storage,
  whitelist: ['auth'], // Only persist the auth slice
};

const rootReducer = combineReducers({
  auth: authReducer,
  team: teamReducer,
  teamStatus: teamStatusReducer,
  teamAll: teamAllReducer,
  quoteStatus: quoteStatusReducer,
  customer: customerReducer,
  customerSites: customerSitesReducer,
  breadcrumb: breadcrumbPathReducer,
  customerStatus: customerStatusReducer,
  customerError: customerErrorReducer,
  // singleCustomerData: singleCustomerReducer,
  product: productReducer,
  productError: productErrorReducer,
  quotes: quoteReducer,
  timesheet: timesheetReducer,
  expense: expenseReducer,
  expenseMetrics: expenseMetricsReducer,
  customerTitles: customerTitlesReducer,
  selectedQuote: singleQuoteReducer,
  selectedQuoteNotes: quoteNotesReducer,
  timesheetCategory: timesheetCategoryReducer,
  timesheetMetrics: timesheetMetricsReducer,
  jobAll: jobAllReducer,
  jobs: jobsReducer,
  jobStatus: jobStatusReducer,
  jobType: jobTypeReducer,
  jobSchedule: jobScheduleReducer,
  selectedJobData: SelectedJobDataReducer,
  jobRecurringType: jobRecurringTypeReducer,
  finances: financesReducer,
  enquiry: enquiryReducer,
  enquiryStatus: enquiryStatusReducer,
  selectedJobNotes: jobNotesReducer,
  export: exportReducer,
  accountBilling: accountBillingReducer,
  pushNotificationCount: pushNotificationCountReducer,
  pushNotification: pushNotificationReducer,
  invoices: invoicesReducer,
  invoiceAllStatus: invoiceAllStatusReducer,
  invoice: invoiceReducer,
  invoicePaymentMethods: invoicePaymentMethodsReducer,
  invoiceAllPayment: invoiceAllPaymentReducer,
  invoiceSinglePayment: invoiceSinglePaymentReducer,
  invoiceAllNotes: invoiceAllNotesReducer,
  invoiceSingleNote: invoiceSingleNoteReducer,
  invoiceAllSignatures: invoiceAllSignaturesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);

// the `RootState` and `AppDispatch` types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
