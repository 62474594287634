// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_METHOD_GET, API_TEAM_MEMBERS } from '@/constants/apiConstants';

export const GetAllTeamMembersData = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_TEAM_MEMBERS + `/all/team-members`;

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
