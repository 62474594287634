import { API_INVOICE_COMMON, API_METHOD_GET } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const GetAllInvoiceStatus = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_INVOICE_COMMON + '/common/status/invoice-status';

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
