import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { TeamShort } from '@/helpers/types';
import { GetAllTeamMembersData } from '@/api/team/getAllTeamMembers';

type FetchAllTeamMembersResponse = {
  data: TeamShort[];
};

type FetchTeamMembersError = string | SerializedError;

export const fetchAllTeamMembers = createAsyncThunk<
  FetchAllTeamMembersResponse, // Return type of the payload creator
  void,
  {
    rejectValue: FetchTeamMembersError;
  }
>('team/fetchAllTeamMembers', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAllTeamMembersData();
    const data: any = response?.data;
    return { data: data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});
