import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { AccountBilling, Pagination } from '@/helpers/types';
import { SearchAccountBillingsData } from '@/api/accountBilling/searchAccountBillings';
import { GetAccountBillingsData } from '@/api/accountBilling/getAccountBillings';

// Define the type for the API response
interface AccountBillingsResponse {
  data: AccountBilling[];
  pagination: Pagination;
}

// Define the type for the thunk's return value
type FetchAccountBillingsResponse = {
  data: AccountBilling[];
  pagination: Pagination;
};

type FetchAccountBillingsError = string | SerializedError;
type SearchAccountBillingsArgs = {
  searchTerm: string;
  pageSize: string;
  pagination: number;
};

export const fetchAccountBillings = createAsyncThunk<
  FetchAccountBillingsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchAccountBillingsError; // Type for rejected value
  }
>('accountBillings/fetchAccountBillings', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAccountBillingsData();
    const data: AccountBillingsResponse = response.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchAccountBillings = createAsyncThunk<
  AccountBillingsResponse,
  SearchAccountBillingsArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchAccountBillingsError;
  }
>(
  'accountBillings/searchAccountBillings',
  async ({ searchTerm, pageSize, pagination }, { rejectWithValue }) => {
    // Destructure the argument
    try {
      const response = await SearchAccountBillingsData(
        searchTerm,
        pageSize,
        pagination,
      ); // Pass both parameters
      const data: AccountBillingsResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
