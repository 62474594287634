import { createSlice } from "@reduxjs/toolkit";

const productErrorSlice = createSlice({
    name: 'productError',
    initialState: {
      error: null,
    },
    reducers: {
      setErrors(state, action) {
        state.error = action.payload;
      },
      clearError(state) {
        state.error = null;
      },
    },
  });
  
  export const { setErrors, clearError } = productErrorSlice.actions;
  export default productErrorSlice.reducer;
  