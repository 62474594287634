// teamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerTitleData } from '@/helpers/types';
import { fetchCustomerTitles } from '@/redux/thunk/customersThunk';

interface CustomerTitlesState {
  customerTitlesData: CustomerTitleData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CustomerTitlesState = {
  customerTitlesData: [],
  status: 'idle',
  error: null,
};

const customerTitlesSlice = createSlice({
  name: 'customer-titles',
  initialState,
  reducers: {
    clearCustomers(state) {
      state.customerTitlesData = initialState.customerTitlesData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerTitles.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchCustomerTitles.fulfilled,
        (state, action: PayloadAction<{ data: CustomerTitleData[] }>) => {
          state.customerTitlesData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchCustomerTitles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearCustomers } = customerTitlesSlice.actions;
export default customerTitlesSlice.reducer;
