import { API_INVOICE, API_METHOD_POST } from '@/constants/apiConstants';
import { CreateInvoiceFromJobsFormData, InvoiceFormData } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const CreateInvoice = async (invoiceData: CreateInvoiceFromJobsFormData) => {
  const method: Method = API_METHOD_POST;
  const url = API_INVOICE;
  const data = invoiceData;

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
