import { InvoiceAllSignatureType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInvoiceAllSignatures } from '../thunk/invoiceThunk';

interface InvoiceSignaturesAllState {
  signatures: InvoiceAllSignatureType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InvoiceSignaturesAllState = {
  signatures: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const invoiceAllSignaturesSlice = createSlice({
  name: 'invoice-all-signatures',
  initialState,
  reducers: {
    clearInvoiceAllSignatures(state) {
      state.signatures = initialState.signatures;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceAllSignatures.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInvoiceAllSignatures.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: InvoiceAllSignatureType[];
          }>,
        ) => {
          state.status = 'succeeded';
          state.signatures = action.payload.data;
        },
      )
      .addCase(fetchInvoiceAllSignatures.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearInvoiceAllSignatures } = invoiceAllSignaturesSlice.actions;
export default invoiceAllSignaturesSlice.reducer;
