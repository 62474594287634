// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_TEAM_MEMBERS, API_METHOD_GET } from '@/constants/apiConstants';

export const GetTeamMembersData = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_TEAM_MEMBERS;

  try {
    const response = await apiCall({
      method,
      url,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
