import { createSlice } from "@reduxjs/toolkit";

const customerErrorSlice = createSlice({
    name: 'customerError',
    initialState: {
      error: null,
    },
    reducers: {
      setErrors(state, action) {
        state.error = action.payload;
      },
      clearError(state) {
        state.error = null;
      },
    },
  });
  
  export const { setErrors, clearError } = customerErrorSlice.actions;
  export default customerErrorSlice.reducer;
  