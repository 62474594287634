import {
  API_GET_ENQUIRY_STATUS,
  API_METHOD_GET,
} from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const GetEnquiryStatus = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_GET_ENQUIRY_STATUS;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
