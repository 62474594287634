import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Export, Pagination } from '@/helpers/types';
import { GetExportsData } from '@/api/export/getExports';
import { SearchExportsData } from '@/api/export/searchExports';
import { GetExportById } from '@/api/export/getExportById';

// Define the type for the API response
interface ExportsResponse {
  data: Export[];
  pagination: Pagination;
}

// Define the type for the thunk's return value
type FetchExportsResponse = {
  data: Export[];
  pagination: Pagination;
};

type FetchExportsError = string | SerializedError;
type SearchExportsArgs = {
  searchTerm: string;
  pageSize: string;
  pagination: number;
  exportType?: string;
};

export const fetchExports = createAsyncThunk<
  FetchExportsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchExportsError; // Type for rejected value
  }
>('exports/fetchExports', async (_, { rejectWithValue }) => {
  try {
    const response = await GetExportsData();
    const data: ExportsResponse = response.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchExports = createAsyncThunk<
  ExportsResponse,
  SearchExportsArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchExportsError;
  }
>(
  'exports/searchExports',
  async (
    { searchTerm, pageSize, pagination, exportType },
    { rejectWithValue },
  ) => {
    // Destructure the argument
    try {
      const response = await SearchExportsData(
        searchTerm,
        pageSize,
        pagination,
        exportType,
      ); // Pass both parameters
      const data: ExportsResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchExportById = createAsyncThunk<
  string, // The expected return type - file URL
  string, // The expected argument type (UUID)
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>('export/fetchExportById', async (exportUuid: string, { rejectWithValue }) => {
  try {
    const response = await GetExportById(exportUuid);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
