import {
  API_EXPENSES,
  API_METHOD_DELETE,
  API_METHOD_POST,
  API_TIMESHEETS,
} from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';
import { array } from 'yup';
import { generateHash } from '@/utility/auth/generateHashValue';

export const ApproveTimesheetData = async (timesheetIds: string[]) => {
  const method: Method = API_METHOD_POST;
  const url = API_TIMESHEETS + `/approve`;
  const data = {
    timesheet_ids: timesheetIds, // Send the array of UUIDs as 'timesheet_ids'
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
