import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_METHOD_GET,
  API_PUSH_NOTIFICATION,
} from '@/constants/apiConstants';

export const PaginatePushNotification = async (
  pageSize?: string,
  pagination?: number,
) => {
  pageSize = pageSize || '10';
  pagination = pagination || 1;
  const method: Method = API_METHOD_GET;
  let url =
    API_PUSH_NOTIFICATION +
    `?` +
    (pageSize && `p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
