import { JobRecurringTypeData } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJobTypes } from '@/redux/thunk/jobTypeThunk';
import { fetchJobRecurringTypes } from '@/redux/thunk/jobRecurringTypeThunk';

interface JobRecurringTypeState {
  jobRecurringType: JobRecurringTypeData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: JobRecurringTypeState = {
  jobRecurringType: [],
  status: 'idle',
  error: null,
};

const jobRecurringTypeSlice = createSlice({
  name: 'job-type',
  initialState,
  reducers: {
    clearJobRecurringType(state) {
      state.jobRecurringType = initialState.jobRecurringType;
    },
  },
  extraReducers: (builder) => {
    // Fetch enquiry status
    builder
      .addCase(fetchJobRecurringTypes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobRecurringTypes.fulfilled,
        (state, action: PayloadAction<{ data: JobRecurringTypeData[] }>) => {
          state.status = 'succeeded';
          state.jobRecurringType = action.payload.data;
        },
      )
      .addCase(fetchJobRecurringTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearJobRecurringType } = jobRecurringTypeSlice.actions;
export default jobRecurringTypeSlice.reducer;
