import { InvoiceSinglePaymentType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInvoiceSinglePayment } from '../thunk/invoicePaymentThunk';

interface InvoiceSinglePaymentState {
  invoicePayment: InvoiceSinglePaymentType;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InvoiceSinglePaymentState = {
  invoicePayment: {
    uuid: '',
    invoice_id: 0,
    date: '',
    method: 0,
    amount: 0,
    pdf_url: '',
    reference: '',
    details: '',
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const invoiceSinglePaymentSlice = createSlice({
  name: 'invoice-single-payment-state',
  initialState,
  reducers: {
    clearInvoiceSinglePayment(state) {
      state.invoicePayment = initialState.invoicePayment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceSinglePayment.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInvoiceSinglePayment.fulfilled,
        (state, action: PayloadAction<InvoiceSinglePaymentType>) => {
          state.status = 'succeeded';
          state.invoicePayment = action.payload;
        },
      )
      .addCase(fetchInvoiceSinglePayment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearInvoiceSinglePayment } = invoiceSinglePaymentSlice.actions;
export default invoiceSinglePaymentSlice.reducer;
