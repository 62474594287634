import { AllInvoiceType, Pagination } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createInvoice, fetchAllInvoices, searchInvoice } from '../thunk/invoiceThunk';

interface InvoiceAllState {
  invoices: AllInvoiceType[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InvoiceAllState = {
  invoices: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const invoiceAllSlice = createSlice({
  name: 'invoice-all',
  initialState,
  reducers: {
    clearInvoices(state) {
      state.invoices = initialState.invoices;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInvoices.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchAllInvoices.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AllInvoiceType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.invoices = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(fetchAllInvoices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Search invoice
    builder
      .addCase(searchInvoice.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchInvoice.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AllInvoiceType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.invoices = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      );

    // Handle create invoice
    builder
      .addCase(createInvoice.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(createInvoice.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.error = null; // Clear any previous errors
        state.fieldErrors = {}; // Clear field errors on success
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.payload as unknown as string;
      });
  },
});

export const { clearInvoices } = invoiceAllSlice.actions;
export default invoiceAllSlice.reducer;
