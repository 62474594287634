// quoteSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusDataType } from '@/helpers/types';
import { fetchQuoteStatus } from '../thunk/quoteStatusThunk';

interface QuoteStatusState {
  statusData: StatusDataType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: QuoteStatusState = {
  statusData: [],
  status: 'idle',
  error: null,
};

const quoteStatusSlice = createSlice({
  name: 'quote-status',
  initialState,
  reducers: {
    clearQuotes(state) {
      state.statusData = initialState.statusData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuoteStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchQuoteStatus.fulfilled,
        (state, action: PayloadAction<{ data: StatusDataType[] }>) => {
          state.statusData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchQuoteStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearQuotes } = quoteStatusSlice.actions;
export default quoteStatusSlice.reducer;
