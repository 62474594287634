import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Note } from '@/helpers/types/quoteTypes';
import { fetchQuoteNotes } from '../thunk/quoteThunk';

interface QuoteState {
    quoteNotes: Note[]
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: QuoteState = {
    quoteNotes: [],
    status: 'idle',
    error: null,
};

const quoteSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        clearQuotes(state) {
            state.quoteNotes = initialState.quoteNotes;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuoteNotes.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchQuoteNotes.fulfilled,
                (
                    state,
                    action: PayloadAction<{ data: Note[] }>,
                ) => {
                    state.quoteNotes = action.payload.data;
                    state.status = 'succeeded';
                },
            )
            .addCase(fetchQuoteNotes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })

    },

});

export const { clearQuotes } = quoteSlice.actions;
export default quoteSlice.reducer;
