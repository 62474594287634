import { Pagination, ProductType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addProduct,
  fetchProducts,
  searchProduct,
  updateProduct,
} from '../thunk/productThunk';

interface ProductState {
  product: ProductType[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: ProductState = {
  product: [],
  status: 'idle',
  error: null,
  pagination: {
    current_page: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    total_pages: 0,
    total_records: 0,
  },
  fieldErrors: {},
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    clearProducts(state) {
      state.product = initialState.product;
      state.pagination = initialState.pagination;
    },
  },
  extraReducers: (builder) => {
    // fetch all products
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchProducts.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: ProductType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.product = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // add new product
    builder
      .addCase(addProduct.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(
        addProduct.fulfilled,
        (state, action: PayloadAction<ProductType>) => {
          state.status = 'succeeded';
          state.product.push(action.payload);
          state.error = null;
          state.fieldErrors = {};
        },
      )
      .addCase(addProduct.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload.message;
          state.fieldErrors = action.payload.messages || {};
        } else {
          state.error = 'An unknown error occurred';
        }
      });

    // search product
    builder
      .addCase(searchProduct.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchProduct.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: ProductType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.product = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(searchProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // update product
    builder
      .addCase(updateProduct.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(
        updateProduct.fulfilled,
        (state, action: PayloadAction<ProductType>) => {
          const index = state.product.findIndex(
            (product) => product.uuid === action.payload.uuid,
          );

          if (index !== -1) {
            state.product[index] = action.payload;
          }

          state.status = 'succeeded';
          state.error = null;
          state.fieldErrors = {};
        },
      )
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload.message;
          state.fieldErrors = action.payload.messages || {};
        } else {
          state.error = 'An unknown error occurred';
        }
      });
  },
});

export const { clearProducts } = productSlice.actions;
export default productSlice.reducer;
