// teamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination, Customer, SingleCustomerData } from '@/helpers/types';
import {
  fetchCustomerById,
  fetchCustomers,
  searchCustomers,
} from '@/redux/thunk/customersThunk';
import { fetchTeamMemberById } from '@/redux/thunk/teamThunk';

interface CustomerState {
  customer: Customer[];
  selectedSingleCustomerData: SingleCustomerData;
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: CustomerState = {
  customer: [],
  selectedSingleCustomerData: {
    uuid: '',
    title: '',
    name: '',
    company_name: '',
    company: {
      id: 0,
      name: '',
    },
    email: '',
    phone: '',
    referred_by: '',
    is_active: 0,
    is_active_label: '',
    notes: [],
    sites: [],
  },
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const customersSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    clearCustomers(state) {
      state.pagination = initialState.pagination;
      state.customer = initialState.customer;
    },
    clearSingleCustomer(state) {
      state.selectedSingleCustomerData =
        initialState.selectedSingleCustomerData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchCustomers.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Customer[]; pagination: Pagination }>,
        ) => {
          state.customer = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(searchCustomers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchCustomers.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Customer[]; pagination: Pagination }>,
        ) => {
          state.customer = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchCustomers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchCustomerById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchCustomerById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.selectedSingleCustomerData = action.payload;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchCustomerById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearCustomers, clearSingleCustomer } = customersSlice.actions;
export default customersSlice.reducer;
