import { API_ENQUIRY, API_METHOD_PUT } from '@/constants/apiConstants';
import { EnquiryType } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateEnquiryNote = async (updatedEnquiryData: any) => {
  const method: Method = API_METHOD_PUT;
  const url =
    API_ENQUIRY +
    '/' +
    updatedEnquiryData.enquiryUuid +
    '/notes/' +
    updatedEnquiryData.uuid;
  const data = {
    note: updatedEnquiryData.note,
    attachment_url: updatedEnquiryData.attachment_url,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
