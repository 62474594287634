import React from 'react';
import styles from './loading.module.css';
import Image from 'next/image';

import logo from '../../../public/assets/images/logo.svg';

const Loading: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white relative overflow-hidden">
      <div className={styles.shine}>
        <Image src={logo} alt="logo" width={192} height="auto" priority />
      </div>
    </div>
  );
};

export default Loading;
