import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { StatusDataType } from '@/helpers/types';
import { GetQuoteStatusData } from '@/api/common/getQuoteStatus';

// Define the type for the API response
interface QuoteStatusResponse {
  data: StatusDataType[];
}

// Define the type for the thunk's return value
type FetchQuoteStatusResponse = {
  data: StatusDataType[];
};

type FetchQuoteStatusError = string | SerializedError;

export const fetchQuoteStatus = createAsyncThunk<
  FetchQuoteStatusResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchQuoteStatusError; // Type for rejected value
  }
>('quote/fetchQuoteStatus', async (_, { rejectWithValue }) => {
  try {
    const response = await GetQuoteStatusData();
    const data: QuoteStatusResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});
