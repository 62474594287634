import { GetAllInvoiceStatus } from '@/api/invoice/getAllInvoiceStatus';
import { InvoiceStatusType } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
type FetchInvoiceStatusResponse = {
  data: InvoiceStatusType[];
};

type FetchInvoiceStatusError = string | SerializedError;

// Fetch all invoice status
export const fetchAllInvoiceStatus = createAsyncThunk<
  FetchInvoiceStatusResponse,
  void,
  { rejectValue: FetchInvoiceStatusError }
>('invoiceStatus/fetchInvoiceAllStatus', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAllInvoiceStatus();
    return response;
  } catch (error: any) {
    if (error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});
