import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_CUSTOMERS, API_METHOD_GET } from '@/constants/apiConstants';

export const GetCustomerSitesData = async (customerId: number) => {
  const method: Method = API_METHOD_GET;
  const url = `${API_CUSTOMERS}/${customerId}/sites`;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
