import { AddEnquiry } from '@/api/enquiry/addEnquiry';
import { DeleteEnquiry } from '@/api/enquiry/deleteEnquiry';
import { AddEnquiryNote } from '@/api/enquiry/enquiryNotes/addEnquiryNote';
import { DeleteEnquiryNote } from '@/api/enquiry/enquiryNotes/deleteEnquiryNote';
import { GetEnquiryNote } from '@/api/enquiry/enquiryNotes/getEnquiryNote';
import { GetEnquiryNotes } from '@/api/enquiry/enquiryNotes/getEnquiryNotes';
import { UpdateEnquiryNote } from '@/api/enquiry/enquiryNotes/updateEnquiryNote';
import { GetEnquiries } from '@/api/enquiry/getEnquiries';
import { GetEnquiry } from '@/api/enquiry/getEnquiry';
import { SearchEnquiry } from '@/api/enquiry/searchEnquiries';
import { UpdateEnquiry } from '@/api/enquiry/updateEnquiry';
import {
  EnquiriesData,
  EnquiryData,
  EnquiryNoteData,
  EnquiryNoteType,
  EnquiryType,
  Pagination,
} from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

type FetchEnquiriesResponse = {
  // data: EnquiriesData;
  data: EnquiryData;
  pagination: Pagination;
};

type FetchNotesResponse = {
  data: EnquiryNoteData;
};

type FetchEnquiryArgs = {
  uuid: string;
};

type FetchNoteArgs = {
  enquiryUuid: string;
  noteUuid: string;
};

type FetchNotesArgs = {
  uuid: string;
};

type SearchEnquiryArgs = {
  searchTerm: string;
  filteredType: number | undefined;
  enquiryCountPerPage: number;
  pageNumber: number;
  filterDateRange: {
    start: string;
    end: string;
  };
  selectedCustomer?: string;
};

type DeleteEnquiryArgs = {
  uuid: string;
};

type DeleteEnquiryNoteArgs = {
  enquiryUuid: string;
  noteUuid: string;
};

type FetchEnquiryError = string | SerializedError;

// Add Enquiry
export const addEnquiry = createAsyncThunk<
  EnquiryType,
  EnquiryType,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'enquiry/addEnquiry',
  async (newEnquiry: EnquiryType, { rejectWithValue }) => {
    try {
      const response = await AddEnquiry(newEnquiry);
      return response;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Fetch enquiries
export const fetchEnquiries = createAsyncThunk<
  FetchEnquiriesResponse,
  void,
  { rejectValue: FetchEnquiryError }
>('enquiry/fetchEnquiries', async (_, { rejectWithValue }) => {
  try {
    const response = await GetEnquiries();
    const data: FetchEnquiriesResponse = response.data;
    return { data: data.data, pagination: data.pagination };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});

// Fetch enquiry
export const fetchEnquiry = createAsyncThunk<
  EnquiryData,
  FetchEnquiryArgs,
  { rejectValue: FetchEnquiryError }
>('enquiry/fetchEnquiry', async ({ uuid }, { rejectWithValue }) => {
  try {
    const response = await GetEnquiry(uuid);
    return response.data;
  } catch (error) {
    if (error.response) {
      const { message, messages } = error.response.data;
      return rejectWithValue({ message, messages });
    }
    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }
  }
  return rejectWithValue({ message: 'An unknown error occurred' });
});

// Update enquiry
export const updateEnquiry = createAsyncThunk<
  EnquiryData,
  EnquiryData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'enquiry/updateEnquiry',
  async ({ uuid, updatedEnquiry }, { dispatch, rejectWithValue }) => {
    try {
      const response = await UpdateEnquiry(uuid, updatedEnquiry);
      return response;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete enquiry
export const deleteEnquiry = createAsyncThunk<
  void,
  DeleteEnquiryArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>('enquiry/deleteEnquiry', async ({ uuid }, { dispatch, rejectWithValue }) => {
  try {
    const response = await DeleteEnquiry(uuid);
    await dispatch(fetchEnquiries());
    return response;
  } catch (error) {
    if (error.response) {
      const { message, messages } = error.response.data;
      return rejectWithValue({ message, messages });
    }
    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }
  }
  return rejectWithValue({ message: 'An unknown error occurred' });
});

// Search enquiry
export const searchEnquiry = createAsyncThunk<
  FetchEnquiriesResponse,
  SearchEnquiryArgs,
  { rejectValue: FetchEnquiryError }
>(
  'enquiry/searchEnquiries',
  async (
    {
      searchTerm,
      filteredType,
      enquiryCountPerPage,
      pageNumber,
      filterDateRange,
      selectedCustomer,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchEnquiry(
        searchTerm,
        filteredType,
        enquiryCountPerPage,
        pageNumber,
        filterDateRange,
        selectedCustomer,
      );
      const data: FetchEnquiriesResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Enquiry notes
// Add enquiry note
export const addEnquiryNote = createAsyncThunk<
  EnquiryNoteType,
  EnquiryNoteType,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'enquiryNote/addEnquiryNote',
  async (newEnquiryNote, { dispatch, rejectWithValue }) => {
    const uuid = newEnquiryNote.uuid;
    try {
      const response = await AddEnquiryNote(newEnquiryNote);
      dispatch(fetchEnquiry({ uuid }));
      return response;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Delete enquiry note
export const deleteEnquiryNote = createAsyncThunk<
  void,
  DeleteEnquiryNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'enquiryNote/deleteEnquiryNote',
  async ({ enquiryUuid, noteUuid }, { dispatch, rejectWithValue }) => {
    const uuid = enquiryUuid;
    try {
      const response = await DeleteEnquiryNote(enquiryUuid, noteUuid);
      dispatch(fetchEnquiry({ uuid }));
      return response;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch enquiry note
export const fetchEnquiryNote = createAsyncThunk<
  EnquiryNoteData,
  FetchNoteArgs,
  { rejectValue: FetchEnquiryError }
>(
  'enquiryNote/fetchEnquiryNote',
  async ({ enquiryUuid, noteUuid }, { rejectWithValue }) => {
    console.log(enquiryUuid, noteUuid);

    try {
      const response = await GetEnquiryNote(enquiryUuid, noteUuid);
      return response.data;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Update enquiry note
export const updateEnquiryNote = createAsyncThunk<
  EnquiryNoteType,
  EnquiryNoteType,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'enquiryNote/updateEnquiryNote',
  async (updatedEnquiryData, { dispatch, rejectWithValue }) => {
    const uuid = updatedEnquiryData.enquiryUuid;
    try {
      const response = await UpdateEnquiryNote(updatedEnquiryData);
      dispatch(fetchEnquiry({ uuid }));
      return response;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch all enquiry notes
export const fetchEnquiryNotes = createAsyncThunk<
  FetchNotesResponse,
  FetchNotesArgs,
  { rejectValue: FetchEnquiryError }
>('enquiryNote/fetchEnquiryNotes', async ({ uuid }, { rejectWithValue }) => {
  try {
    const response = await GetEnquiryNotes(uuid);
    const data: FetchNotesResponse = response;
    return { data: data.data };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});
