import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleQuoteByUuid } from '@/helpers/types/quoteTypes';
import { fetchQuoteByUuid, fetchQuoteDownloadLink } from '../thunk/quoteThunk';

interface SingleQuote {
    selectedQuoteData: SingleQuoteByUuid;
    downloadLink: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: SingleQuote = {
    selectedQuoteData: {
        uuid: '',
        company_id: '',
        customer: {
            id: '',
            name: '',
            address: '',
            phone: '',
            email: ''
        },
        created: '',
        customer_site: {
            address: '',
            name: '',
            uuid: '',
        },
        quote_number: '',
        rating: 0,
        title: '',
        client_message: '',
        disclaimer: '',
        tax_rate: 0,
        tax_rate_id: null,
        subtotal: 0,
        discount: 0,
        tax_amount: 0,
        total: 0,
        deposit_required: 0,
        status: 0,
        products: [],
        notes: []
    },
    downloadLink: '',
    status: 'idle',
    error: null,
};

const singleQuoteSlice = createSlice({
    name: 'sinle-quote',
    initialState,
    reducers: {
        clearSingleQuote(state) {
            state.selectedQuoteData = initialState.selectedQuoteData;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuoteByUuid.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchQuoteByUuid.fulfilled,
                (state, action: PayloadAction<{ data: SingleQuoteByUuid }>) => {
                    state.selectedQuoteData = action.payload.data;
                    state.status = 'succeeded';
                },
            )
            .addCase(fetchQuoteByUuid.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });

        builder
            .addCase(fetchQuoteDownloadLink.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchQuoteDownloadLink.fulfilled,
                (state, action: PayloadAction<{ data: string }>) => {
                    state.downloadLink = action.payload.data;
                    state.status = 'succeeded';
                },
            )
            .addCase(fetchQuoteDownloadLink.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearSingleQuote } = singleQuoteSlice.actions;
export default singleQuoteSlice.reducer;
