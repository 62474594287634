'use client';
import * as React from 'react';
import StoreProvider from '@/redux/storeProvider';
import { NextUIProvider } from '@nextui-org/system';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { ThemeProviderProps } from 'next-themes/dist/types';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import '../styles/globals.css';
import RouteLoader from '@/components/loading/RouteLoader';

export interface ProvidersProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
}

export function Providers({ children, themeProps }: ProvidersProps) {
  return (
    <StoreProvider>
      <NextUIProvider>
        <NextThemesProvider
          defaultTheme="light"
          attribute="class"
          {...themeProps}
        >
          <RouteLoader>{children}</RouteLoader>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar
            closeOnClick
            pauseOnHover
            draggable
            pauseOnFocusLoss
          />
        </NextThemesProvider>
      </NextUIProvider>
    </StoreProvider>
  );
}
