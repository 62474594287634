import { API_INVOICE, API_METHOD_POST } from '@/constants/apiConstants';
import { AddInvoiceNoteData } from '@/redux/thunk/invoiceNoteThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const CreateInvoiceNote = async (
  newInvoiceNoteData: AddInvoiceNoteData,
) => {
  const method: Method = API_METHOD_POST;
  const url = API_INVOICE + '/' + newInvoiceNoteData.invoiceUuid + '/notes';
  const data = {
    note: newInvoiceNoteData.note,
    attachment: newInvoiceNoteData.attachment,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
