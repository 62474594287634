import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { StatusDataType } from '@/helpers/types';
import { GetCustomerStatusData } from '@/api/common/getCustomerStatus';

// Define the type for the API response
interface CustomerStatusResponse {
  data: StatusDataType[];
}

// Define the type for the thunk return value
type FetchCustomerStatusResponse = {
  data: StatusDataType[];
};

type FetchCustomerStatusError = string | SerializedError;

export const fetchCustomerStatus = createAsyncThunk<
  FetchCustomerStatusResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchCustomerStatusError; // Type for rejected value
  }
>('customers/fetchCustomerStatus', async (_, { rejectWithValue }) => {
  try {
    const response = await GetCustomerStatusData();
    const data: CustomerStatusResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});
