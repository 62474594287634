import { useState, useEffect, useRef } from 'react';

const useRouteLoading = () => {
  const [loading, setLoading] = useState(true);
  const initialLoad = useRef(true);

  useEffect(() => {
    if (initialLoad.current) {
      // Initial load: show loading spinner
      const timer = setTimeout(() => {
        setLoading(false);
        initialLoad.current = false;
      }, 500);

      return () => clearTimeout(timer);
    }
  }, []); // Empty dependency array: only run on mount

  return loading;
};

export default useRouteLoading;
