import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { calculateFinances } from '@/redux/thunk/calculateFinancesThunk';

// Define the interface for the response data
interface FinanceData {
  sub_total: number;
  total: number;
  tax_amount: number;
  estimated_margin: number;
}

// Define the interface for the state
interface FinanceState {
  data: FinanceData | null;
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: FinanceState = {
  data: null,
  loading: false,
  error: null,
};

// Create the slice
const calculateFinancesSlice = createSlice({
  name: 'calculateFinances',
  initialState,
  reducers: {
    clearCalculations(state) {
      state.sites = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateFinances.pending, (state) => {
        state.loading = true; // Set loading state to true
        state.error = null; // Reset error state
      })
      .addCase(
        calculateFinances.fulfilled,
        (state, action: PayloadAction<FinanceData>) => {
          // Change the payload type to FinanceData
          state.loading = false; // Set loading state to false
          state.data = action.payload; // Store the retrieved finance data
        },
      )
      .addCase(calculateFinances.rejected, (state, action) => {
        state.loading = false; // Set loading state to false
        state.error = action.payload as string; // Set error message
      });
  },
});

// Export actions
export const { startLoading, loadingSuccess, loadingError } =
  calculateFinancesSlice.actions;

// Export the reducer to be used in the store
export default calculateFinancesSlice.reducer;
