import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_METHOD_GET, API_SCHEDULE_JOBS } from '@/constants/apiConstants';
import { urlEncode } from '@/helpers/common';
import { DateValue } from '@internationalized/date';

export const SearchJobsData = async (
  searchTerm: string | null,
  startDate?: string | DateValue | null,
  endDate?: string | DateValue | null,
) => {
  const method: Method = API_METHOD_GET;

  let url =
    API_SCHEDULE_JOBS +
    `?search=${urlEncode(searchTerm)}` +
    `&f-start_date=${startDate}` +
    `&f-end_date=${endDate}`;

  // let url = API_JOBS;

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
