import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_METHOD_PUT, API_TIMESHEETS } from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';
import { TimesheetRequest } from '@/helpers/types';

export const UpdateTimesheet = async (
  timesheetUuid: string,
  values: TimesheetRequest,
) => {
  const method: Method = API_METHOD_PUT;
  const url = `${API_TIMESHEETS}/${timesheetUuid}`;
  const data = values;

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    return await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    }); // Return the response data for further use
  } catch (error) {
    throw error;
  }
};
