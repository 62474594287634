import { API_METHOD_GET, API_PRODUCTS } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const GetProduct = async (uuid: string) => {
  const method: Method = API_METHOD_GET;
  const url = API_PRODUCTS + '/' + uuid;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
