import { JobStatusData } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJobStatus } from '@/redux/thunk/jobStatusThunk';

interface JobStatusState {
  jobStatus: JobStatusData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: JobStatusState = {
  jobStatus: [],
  status: 'idle',
  error: null,
};

const jobStatusSlice = createSlice({
  name: 'job-state',
  initialState,
  reducers: {
    clearJobStatus(state) {
      state.jobStatus = initialState.jobStatus;
    },
  },
  extraReducers: (builder) => {
    // Fetch enquiry status
    builder
      .addCase(fetchJobStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobStatus.fulfilled,
        (state, action: PayloadAction<{ data: JobStatusData[] }>) => {
          state.status = 'succeeded';
          state.jobStatus = action.payload.data;
        },
      )
      .addCase(fetchJobStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearJobStatus } = jobStatusSlice.actions;
export default jobStatusSlice.reducer;
