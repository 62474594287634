// teamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination, SingleExpenseData, Expense } from '@/helpers/types';
import {
  fetchExpenseById,
  fetchExpenses,
  searchExpenses,
} from '@/redux/thunk/expensesThunk';

interface ExpenseState {
  expense: Expense[];
  selectedSingleExpenseData: SingleExpenseData;
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: ExpenseState = {
  expense: [],
  selectedSingleExpenseData: {
    uuid: '',
    job: {
      id: '',
      name: '',
    },
    name: '',
    date: '',
    details: '',
    amount: '',
    attachment_url: '',
  },
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const expensesSlice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    clearExpenses(state) {
      state.pagination = initialState.pagination;
      state.expense = initialState.expense;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchExpenses.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Expense[]; pagination: Pagination }>,
        ) => {
          state.expense = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchExpenses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(searchExpenses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchExpenses.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Expense[]; pagination: Pagination }>,
        ) => {
          state.expense = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchExpenses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchExpenseById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchExpenseById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.selectedSingleExpenseData = action.payload;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchExpenseById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearExpenses } = expensesSlice.actions;
export default expensesSlice.reducer;
