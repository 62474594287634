import { InvoiceStatusType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllInvoiceStatus } from '../thunk/invoiceStatusThunk';

interface InvoiceAllStatusState {
  invoiceStatus: InvoiceStatusType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: InvoiceAllStatusState = {
  invoiceStatus: [],
  status: 'idle',
  error: null,
};

const invoiceAllStatusSlice = createSlice({
  name: 'invoice-all-status-state',
  initialState,
  reducers: {
    clearAllInvoiceStatus(state) {
      state.invoiceStatus = initialState.invoiceStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInvoiceStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchAllInvoiceStatus.fulfilled,
        (state, action: PayloadAction<{ data: InvoiceStatusType[] }>) => {
          state.status = 'succeeded';
          state.invoiceStatus = action.payload.data;
        },
      )
      .addCase(fetchAllInvoiceStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearAllInvoiceStatus } = invoiceAllStatusSlice.actions;
export default invoiceAllStatusSlice.reducer;
