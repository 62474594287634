import { EnquiryStatusData } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchEnquiryStatus } from '../thunk/enquiryStatusThunk';

interface EnquiryStatusState {
  enquiryStatus: EnquiryStatusData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: EnquiryStatusState = {
  enquiryStatus: [],
  status: 'idle',
  error: null,
};

const enquiryStatusSlice = createSlice({
  name: 'enquiry-state',
  initialState,
  reducers: {
    clearEnquiryStatus(state) {
      state.enquiryStatus = initialState.enquiryStatus;
    },
  },
  extraReducers: (builder) => {
    // Fetch enquiry status
    builder
      .addCase(fetchEnquiryStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEnquiryStatus.fulfilled,
        (state, action: PayloadAction<{ data: EnquiryStatusData[] }>) => {
          state.status = 'succeeded';
          state.enquiryStatus = action.payload.data;
        },
      )
      .addCase(fetchEnquiryStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearEnquiryStatus } = enquiryStatusSlice.actions;
export default enquiryStatusSlice.reducer;
