import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { CalculateFinances } from '@/api/common/calculateFinances'; // Assuming this is your API function

// Define the parameters type for the thunk
interface Product {
  item_type: number;
  product_id?: string; // Optional, since it's not present for item_type 2
  name?: string; // Optional, since it's only present for item_type 2
  entered_date: string;
  description?: string | null;
  unit_cost: number;
  unit_price: number;
  quantity: number;
}

// Define the parameters type for the thunk
interface CalculateFinancesParams {
  tax_rate: number;
  discount: number;
  products: Product[];
}

// Define the return type for the thunk
interface CalculateFinancesResponse {
  sub_total: number;
  total: number;
  tax_amount: number;
  estimated_margin: number;
}

// Define the return type for the thunk
type FetchFinancesError = string | SerializedError;

// Create an async thunk for calculating finances
export const calculateFinances = createAsyncThunk<
  CalculateFinancesResponse, // Return type
  CalculateFinancesParams, // Parameters type
  { rejectValue: FetchFinancesError } // Reject value type
>(
  'finances/calculate',
  async ({ tax_rate, discount, products }, { rejectWithValue }) => {
    try {
      const response = await CalculateFinances({
        tax_rate,
        discount,
        products,
      });
      const data: CalculateFinancesResponse = response.data;
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
