import { InvoiceSinglePaymentType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInvoiceAllPayments } from '../thunk/invoicePaymentThunk';

interface InvoiceAllPaymentState {
  invoicePayments: InvoiceSinglePaymentType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InvoiceAllPaymentState = {
  invoicePayments: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const invoiceAllPaymentSlice = createSlice({
  name: 'invoice-all-payments',
  initialState,
  reducers: {
    clearInvoicePayments(state) {
      state.invoicePayments = initialState.invoicePayments;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceAllPayments.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInvoiceAllPayments.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: InvoiceSinglePaymentType[];
          }>,
        ) => {
          state.status = 'succeeded';
          state.invoicePayments = action.payload.data;
        },
      )
      .addCase(fetchInvoiceAllPayments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearInvoicePayments } = invoiceAllPaymentSlice.actions;
export default invoiceAllPaymentSlice.reducer;
