import { GetEnquiryStatus } from '@/api/enquiry/getEnquiryStatus';
import { EnquiryStatusData } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

type FetchEnquiryStatusResponse = {
  data: EnquiryStatusData[];
};

type FetchEnquiryStatusError = string | SerializedError;

// Fetch enquiry status
export const fetchEnquiryStatus = createAsyncThunk<
  FetchEnquiryStatusResponse,
  void,
  { rejectValue: FetchEnquiryStatusError }
>('enquiryStatus/fetchEnquiryStatus', async (_, { rejectWithValue }) => {
  try {
    const response = await GetEnquiryStatus();
    return response;
  } catch (error) {
    if (error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});
