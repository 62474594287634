import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_ACCOUNT_BILLING,
  API_EXPORT,
  API_METHOD_GET,
} from '@/constants/apiConstants';

export const GetAccountBillingsData = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_ACCOUNT_BILLING;

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
