import { AddInvoice } from '@/api/invoice/addInvoice';
import { AddInvoiceSignature } from '@/api/invoice/addInvoiceSignature';
import { CreateInvoice } from '@/api/invoice/createInvoice';
import { DeleteSingleInvoice } from '@/api/invoice/deleteSingleInvoice';
import { GetAllInvoices } from '@/api/invoice/getAllInvoices';
import { GetInvoiceAllSignatures } from '@/api/invoice/getInvoiceAllSignatures';
import { GetSingleInvoice } from '@/api/invoice/getSingleInvoice';
import { SearchInvoice } from '@/api/invoice/searchInvoice';
import { SendInvoiceEmail } from '@/api/invoice/sendInvoiceEmail';
import { UpdateInvoice } from '@/api/invoice/updateInvoice';
import {
  AllInvoiceType,
  CreateInvoiceFromJobsFormData,
  InvoiceAllSignatureType,
  InvoiceFormData,
  InvoiceType,
  Pagination,
} from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Types
type FetchAllInvoicesResponse = {
  data: AllInvoiceType[];
  pagination: Pagination;
};

type SearchInvoiceArgs = {
  searchTerm: string;
  filteredType: number | undefined;
  invoiceCountPerPage: number;
  pageNumber: number;
  filterDateRange: {
    start: string;
    end: string;
  };
  selectedCustomer?: string;
};

type FetchSingleInvoiceArgs = {
  invoiceUuid: string;
};

type FetchInvoiceAllSignaturesResponse = {
  data: InvoiceAllSignatureType[];
};

type AddInvoiceResponse = {
  data: InvoiceType;
  status: string;
};

type UpdateInvoiceResponse = {
  data: InvoiceType;
  status: string;
};

export interface UpdatedInvoiceFormData extends InvoiceFormData {
  status: number;
  invoiceUuid: string;
}

type DeleteInvoiceResponse = {
  status: string;
};

type DeleteSingleInvoiceArgs = {
  invoiceUuid: string;
};

type InvoiceSendEmailResponse = {
  message: string;
  status: string;
};

type InvoiceSendEmailArgs = {
  invoiceUuid: string;
};

type fetchInvoiceAllSignaturesArgs = {
  invoiceUuid: string;
};

interface CreateInvoiceSignatureResponse {
  message(message: any): unknown;
  data: any;
}

interface CreateInvoiceSignatureArgs {
  uuid: string;
  payload: {
    signature: string;
    send_copy_to_customer?: boolean;
  };
}

// Used in job module
interface CreateInvoiceDataType {
  data: InvoiceType;
  message: string;
}

interface CreateInvoiceArgs extends CreateInvoiceFromJobsFormData { }

export interface InvoiceFormDataNote {
  note: string;
  attachment: string;
}

type FetchInvoiceError = string | SerializedError;

// Fetch all invoices
export const fetchAllInvoices = createAsyncThunk<
  FetchAllInvoicesResponse,
  void,
  { rejectValue: FetchInvoiceError }
>('invoice/fetchAllInvoices', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAllInvoices();
    const data: FetchAllInvoicesResponse = response.data;
    return { data: data.data, pagination: data.pagination };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});

// Fetch single invoice
export const fetchSingleInvoice = createAsyncThunk<
  InvoiceType,
  FetchSingleInvoiceArgs,
  { rejectValue: FetchInvoiceError }
>(
  'invoice/fetchSingleInvoice',
  async ({ invoiceUuid }, { rejectWithValue }) => {
    try {
      const response = await GetSingleInvoice(invoiceUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch search invoices
export const searchInvoice = createAsyncThunk<
  FetchAllInvoicesResponse,
  SearchInvoiceArgs,
  { rejectValue: FetchInvoiceError }
>(
  'invoice/searchInvoice',
  async (
    {
      searchTerm,
      filteredType,
      invoiceCountPerPage,
      pageNumber,
      filterDateRange,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchInvoice(
        searchTerm,
        filteredType,
        invoiceCountPerPage,
        pageNumber,
        filterDateRange,
      );
      const data: FetchAllInvoicesResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add invoice
export const addInvoice = createAsyncThunk<
  AddInvoiceResponse,
  // InvoiceType,
  InvoiceFormData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoice/addInvoice',
  async (newInvoiceData: InvoiceFormData, { rejectWithValue }) => {
    try {
      const response = await AddInvoice(newInvoiceData);
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Update invoice
export const updateInvoice = createAsyncThunk<
  UpdateInvoiceResponse,
  UpdatedInvoiceFormData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoice/updateInvoice',
  async (updatedInvoiceData: UpdatedInvoiceFormData, { rejectWithValue }) => {
    try {
      const response = await UpdateInvoice(updatedInvoiceData);
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete single invoice
export const deleteSingleInvoice = createAsyncThunk<
  DeleteInvoiceResponse,
  DeleteSingleInvoiceArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoice/deleteSingleInvoice',
  async ({ invoiceUuid }, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteSingleInvoice(invoiceUuid);
      await dispatch(fetchAllInvoices());
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Send email
export const sendInvoiceEmail = createAsyncThunk<
  InvoiceSendEmailResponse,
  InvoiceSendEmailArgs,
  {
    rejectValue: FetchInvoiceError;
  }
>('invoice/sendInvoiceEmail', async ({ invoiceUuid }, { rejectWithValue }) => {
  try {
    const response = await SendInvoiceEmail(invoiceUuid);
    return response;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});

// Create signature
export const createInvoiceSignature = createAsyncThunk<
  CreateInvoiceSignatureResponse,
  CreateInvoiceSignatureArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoice/createInvoiceSignature',
  async (
    invoiceSignatureData: CreateInvoiceSignatureArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await AddInvoiceSignature(
        invoiceSignatureData?.uuid,
        invoiceSignatureData?.payload,
      );
      toast.success(response?.message);
      await dispatch(
        fetchInvoiceAllSignatures({ invoiceUuid: invoiceSignatureData.uuid }),
      );
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

// Fetch all signatures
export const fetchInvoiceAllSignatures = createAsyncThunk<
  FetchInvoiceAllSignaturesResponse,
  fetchInvoiceAllSignaturesArgs,
  { rejectValue: FetchInvoiceError }
>(
  'invoice/fetchInvoiceAllSignatures',
  async ({ invoiceUuid }, { rejectWithValue }) => {
    try {
      const response = await GetInvoiceAllSignatures(invoiceUuid);
      // const data: FetchInvoiceAllSignaturesResponse = response;
      // return { data: data.data };
      return response;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

// Used in Job module
export const createInvoice = createAsyncThunk<
  CreateInvoiceDataType,
  CreateInvoiceArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoice/createInvoice',
  async (newInvoice: CreateInvoiceFromJobsFormData, { rejectWithValue }) => {
    try {
      const response = await CreateInvoice(newInvoice);
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);
