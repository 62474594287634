import { AddProduct } from '@/api/products/addProduct';
import { DeleteProduct } from '@/api/products/deleteProduct';
import { GetProduct } from '@/api/products/getProduct';
import { GetProducts } from '@/api/products/getProducts';
import { SearchProduct } from '@/api/products/searchProduct';
import { UpdateProduct } from '@/api/products/updateProduct';
import { Pagination, ProductType } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

interface ProductResponse {
  data: ProductType[];
  pagination: Pagination;
}

type FetchProductResponse = {
  data: ProductType[];
  pagination: Pagination;
};

type SearchProductArgs = {
  searchTerm: string;
  filteredType: number;
  itemCountPerPage: number;
  pageNumber: number;
  uuid: string;
};

type FetchSingleProductArgs = {
  uuid: string;
};

type DeleteProductArgs = string;

type FetchProductError = string | SerializedError;

// fetch product
export const fetchProducts = createAsyncThunk<
  FetchProductResponse,
  void,
  { rejectValue: FetchProductError }
>('products/fetchProducts', async (_, { rejectWithValue }) => {
  try {
    const response = await GetProducts();
    const data: ProductResponse = response.data;
    return { data: data.data, pagination: data.pagination };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});

// fetch single product
export const fetchSingleProduct = createAsyncThunk<
  ProductType,
  FetchSingleProductArgs,
  { rejectValue: FetchProductError }
>('products/fetchSingleProduct', async ({ uuid }, { rejectWithValue }) => {
  try {
    const response = await GetProduct(uuid);
    return response;
  } catch (error) {
    if (error.response) {
      const { message, messages } = error.response.data;
      return rejectWithValue({ message, messages });
    }
    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }
  }
  return rejectWithValue({ message: 'An unknown error occurred' });
});

// add product
export const addProduct = createAsyncThunk<
  ProductType,
  ProductType,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'products/addProduct',
  async (newProduct: ProductType, { dispatch, rejectWithValue }) => {
    try {
      const response = await AddProduct(newProduct);
      // await dispatch(fetchProducts());
      return response.data;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// update product
export const updateProduct = createAsyncThunk<
  ProductType,
  ProductType,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'products/updateProduct',
  async ({ uuid, updatedProduct }, { dispatch, rejectWithValue }) => {
    try {
      const response = await UpdateProduct(uuid, updatedProduct);
      await dispatch(fetchProducts());
      return response;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// delete product
export const deleteProduct = createAsyncThunk<
  void,
  DeleteProductArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'products/deleteProduct',
  async ({ productUuid }, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteProduct(productUuid);
      await dispatch(fetchProducts());
      return response;
    } catch (error) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// search product
export const searchProduct = createAsyncThunk<
  FetchProductResponse,
  SearchProductArgs,
  { rejectValue: FetchProductError }
>(
  'products/searchProduct',
  async (
    { searchTerm, filteredType, itemCountPerPage, pageNumber },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchProduct(
        searchTerm,
        filteredType,
        itemCountPerPage,
        pageNumber,
      );
      const data: ProductResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);
