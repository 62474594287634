import { InvoiceNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInvoiceAllNotes } from '../thunk/invoiceNoteThunk';

interface InvoiceAllNoteState {
  invoiceNotes: InvoiceNoteType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InvoiceAllNoteState = {
  invoiceNotes: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const invoiceAllNoteSlice = createSlice({
  name: 'invoice-all-note',
  initialState,
  reducers: {
    clearInvoiceNotes(state) {
      state.invoiceNotes = initialState.invoiceNotes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceAllNotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInvoiceAllNotes.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: InvoiceNoteType[];
          }>,
        ) => {
          state.status = 'succeeded';
          state.invoiceNotes = action.payload.data;
        },
      )
      .addCase(fetchInvoiceAllNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearInvoiceNotes } = invoiceAllNoteSlice.actions;
export default invoiceAllNoteSlice.reducer;
