import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Job, SingleJob } from '@/helpers/types';
import { GetJobsData } from '@/api/job/getJobs';
import { SearchJobsData } from '@/api/job/getScheduledJobs';
import { getJobDetailsByUuid } from '@/api/job/getJobDetailsByUuid';
import { DateValue } from '@internationalized/date';

type FetchJobsResponse = {
  data: Job[];
};

type SearchJobsArgs = {
  searchTerm: string | null;
  startDate?: string | null | DateValue;
  endDate?: string | null | DateValue;
};

type FetchJobByUuidResponse = {
  data: SingleJob;
};

type FetchJobsError = string | SerializedError;

type FetchJobByUuidArgs = string;

export const fetchJobs = createAsyncThunk<
  FetchJobsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchJobsError; // Type for rejected value
  }
>('jobSchedule/fetchJobs', async (_, { rejectWithValue }) => {
  try {
    const response = await GetJobsData();
    const data: FetchJobsResponse = response.data;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchJobs = createAsyncThunk<
  FetchJobsResponse,
  SearchJobsArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchJobsError;
  }
>(
  'jobSchedule/searchJobs',
  async ({ searchTerm, startDate, endDate }, { rejectWithValue }) => {
    // Destructure the argument
    try {
      const response = await SearchJobsData(searchTerm, startDate, endDate); // Pass both parameters
      const data: FetchJobsResponse = response.data;
      return { data: data.data }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchJobByUuid = createAsyncThunk<
  FetchJobByUuidResponse, // Return type of the payload creator
  FetchJobByUuidArgs, //  Argument type of the payload creator
  { rejectValue: FetchJobsError }
>('jobSchedule/fetchJobByUuid', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await getJobDetailsByUuid(uuid);
    return response;
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});
