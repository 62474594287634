import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from '@/helpers/types';
import { fetchSubscription } from '@/redux/thunk/subscriptionCheckThunk';
import { loginResponse } from '@/helpers/types/authTypes';
import { fetchLogin, fetchLogout } from '@/redux/thunk/authThunk';

interface AuthState {
  isAuthenticated: boolean;
  user: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string | null;
    labor_cost: number | string | null;
    company_id: number | null;
    company_name: string | null;
    is_active: boolean | null;
    uuid: string;
    role: string;
    permissions: string[];
  } | null;
  access_token: {
    expires_at: string;
    token: string;
  } | null;
  refresh_token: {
    expires_at: string;
    token: string;
  } | null;
  subscription: Subscription;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  access_token: null,
  refresh_token: null,
  subscription: {
    users: 0,
    is_trial: false,
    expiry: '',
    active_users: 0,
    available_months: 0,
    is_expired: false,
  },
  status: 'idle',
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<loginResponse>,
    ) => {
      state.isAuthenticated = true;
      state.user = action.payload?.user;
      state.refresh_token = action.payload?.refresh_token;
      state.access_token = action.payload?.access_token;
      state.subscription = action.payload?.subscription;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.refresh_token = null;
      state.access_token = null;
      state.subscription = initialState.subscription;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchLogin.fulfilled,
        (
          state,
          action: PayloadAction<loginResponse>,
        ) => {
          state.isAuthenticated = true;
          state.user = action.payload?.user;
          state.refresh_token = action.payload?.refresh_token;
          state.access_token = action.payload?.access_token;
          state.subscription = action.payload?.subscription;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as unknown as string;
        state.isAuthenticated = false;
        state.user = null;
        state.refresh_token = null;
        state.access_token = null;
        state.subscription = initialState.subscription;
      });

    builder
      .addCase(fetchLogout.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchLogout.fulfilled,
        (state, action: PayloadAction<{ data: [] }>) => {
          state.isAuthenticated = false;
          state.user = null;
          state.refresh_token = null;
          state.access_token = null;
          state.subscription = initialState.subscription;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchLogout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as unknown as string;
      });

    builder.addCase(
      fetchSubscription.fulfilled,
      (state, action: PayloadAction<{ data: Subscription }>) => {
        state.subscription = action.payload.data;
      },
    );
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
