import { API_INVOICE, API_METHOD_GET } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const GetInvoiceSingleNote = async (
  invoiceUuid: string,
  noteUuid: string,
) => {
  const method: Method = API_METHOD_GET;
  const url = API_INVOICE + '/' + invoiceUuid + '/' + 'notes' + '/' + noteUuid;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
