import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  Pagination,
  Timesheet,
  TimesheetCategoryData,
  TimesheetMetricsData,
  TimesheetRequest,
} from '@/helpers/types';
import { GetTimesheetsData } from '@/api/timesheet/getTimesheets';
import { SearchTimesheetsData } from '@/api/timesheet/searchTimesheets';
import { GetTimesheetsMetrics } from '@/api/timesheet/getTimesheetsMetrics';
import { DeleteTimesheetData } from '@/api/timesheet/deleteTimesheet';
import { AddTimesheet } from '@/api/timesheet/addTimesheet';
import { toast } from 'react-toastify';
import { GetTimesheetCategory } from '@/api/timesheet/getTimesheetCategory';
import { GetTimesheetById } from '@/api/timesheet/getTimesheetById';
import { UpdateTimesheet } from '@/api/timesheet/updateTimesheet';
import { ApproveTimesheetData } from '@/api/timesheet/approveTimesheet';

// Define the type for the API response
interface TimesheetResponse {
  data: Timesheet[];
  pagination: Pagination;
}

interface TimesheetMetricsResponse {
  data: TimesheetMetricsData;
}

type TimesheetMetricsError = string | SerializedError;

// Define the type for the thunk's return value
type FetchTimesheetsResponse = {
  data: Timesheet[];
  pagination: Pagination;
};

interface TimesheetCategoryResponse {
  data: TimesheetCategoryData[];
}

type FetchTimesheetsError = string | SerializedError;
type SearchTimesheetsArgs = {
  searchTerm: string;
  pageSize: string;
  pagination: number;
  selectedTeamMember?: string;
  startDate?: string | null;
  endDate?: string | null;
};
type DeleteTimesheetsArgs = string;

type MetricsTimesheetsResponse = {
  status: string; // e.g., "SUCCESS"
  data: {
    total_timesheet: number; // Total hours value
  };
};
type ApproveTimesheetsArgs = string[];

type updateTimesheetArgs = {
  timesheetUuid: string;
  timesheetData: TimesheetRequest;
};

export const fetchTimesheets = createAsyncThunk<
  FetchTimesheetsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchTimesheetsError; // Type for rejected value
  }
>('timesheet/fetchTimesheets', async (_, { rejectWithValue }) => {
  try {
    const response = await GetTimesheetsData();
    const data: TimesheetResponse = response.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchTimesheets = createAsyncThunk<
  TimesheetResponse,
  SearchTimesheetsArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchTimesheetsError;
  }
>(
  'timesheet/searchTimesheets',
  async (
    {
      searchTerm,
      pageSize,
      pagination,
      selectedTeamMember,
      startDate,
      endDate,
    },
    { rejectWithValue },
  ) => {
    // Destructure the argument
    try {
      const response = await SearchTimesheetsData(
        searchTerm,
        pageSize,
        pagination,
        selectedTeamMember,
        startDate,
        endDate,
      ); // Pass both parameters
      const data: TimesheetResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteTimesheet = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteTimesheetsArgs, // Argument type of the payload creator
  {
    rejectValue: FetchTimesheetsError; // Type for rejected value
  }
>(
  'team/deleteTimesheet',
  async (timesheetUuId: string, { dispatch, rejectWithValue }) => {
    try {
      // Call the API to delete the team member
      await DeleteTimesheetData(timesheetUuId);
      toast.success('Timesheet deleted successfully!');
      // After successful deletion, dispatch fetchTeamMembers to refresh the list
      dispatch(fetchTimesheets());
      dispatch(fetchTimesheetsMetrics());
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

// Thunk to fetch a team member by ID (UUID)
export const fetchTimesheetById = createAsyncThunk<
  Timesheet, // The expected return type
  string, // The expected argument type (UUID)
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>(
  'timesheet/fetchTimesheetById',
  async (timesheetUuid: string, { rejectWithValue }) => {
    try {
      const response = await GetTimesheetById(timesheetUuid);
      return response.data; // Return the single team member data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const addTimeSheet = createAsyncThunk<
  Timesheet,
  TimesheetRequest,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'timesheet/addTimeSheet',
  async (newTimeSheet: TimesheetRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await AddTimesheet(newTimeSheet); // Function to send a POST request
      toast.success('Timesheet added successfully!');
      dispatch(fetchTimesheets());
      dispatch(fetchTimesheetsMetrics());
      return response.data; // Return the created team member
    } catch (error) {
      // @ts-ignore
      if (error.response) {
        // @ts-ignore
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const fetchTimesheetCategory = createAsyncThunk<
  TimesheetCategoryResponse, // The expected return type
  void,
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>('timesheet/fetchTimesheetCategory', async (_, { rejectWithValue }) => {
  try {
    const data: TimesheetCategoryResponse = await GetTimesheetCategory();
    return { data: data.data }; // Return formatted data
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
export const updateTimesheet = createAsyncThunk<
  Timesheet,
  updateTimesheetArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'timesheet/updateTimesheet',
  async ({ timesheetUuid, timesheetData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await UpdateTimesheet(timesheetUuid, timesheetData); // Function to send a POST request
      toast.success(response.message);
      dispatch(fetchTimesheets());
      dispatch(fetchTimesheetsMetrics());
      return response; // Return the created customer site
    } catch (error) {
      // @ts-ignore
      if (error.response) {
        // @ts-ignore
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const fetchTimesheetsMetrics = createAsyncThunk<
  TimesheetMetricsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: TimesheetMetricsError; // Type for rejected value
  }
>('timesheet/fetchTimesheetMetrics', async (_, { rejectWithValue }) => {
  try {
    const response = await GetTimesheetsMetrics();
    const data: TimesheetMetricsResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const approveTimesheets = createAsyncThunk<
  void, // Return type of the payload creator
  ApproveTimesheetsArgs, // Argument type of the payload creator
  {
    rejectValue: FetchTimesheetsError; // Type for rejected value
  }
>(
  'timesheet/approveTimesheets',
  async (timesheetUuIds: string[], { dispatch, rejectWithValue }) => {
    try {
      // Call the API to approve the timesheet
      await ApproveTimesheetData(timesheetUuIds);

      toast.success('Timesheet approved successfully!');
      // After successful deletion, dispatch fetchTimesheet to refresh the list
      dispatch(fetchTimesheets());
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
