import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Job, Pagination } from '@/helpers/types';
import { createJob, fetchJobs, searchJobs } from '@/redux/thunk/jobThunk';
import { fetchExpenseById } from '@/redux/thunk/expensesThunk';

interface JobState {
  job: Job[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: JobState = {
  job: [], // Initialize as null for a single team member
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    clearJob(state) {
      state.pagination = initialState.pagination;
      state.job = initialState.job;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchJobs (existing functionality)
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobs.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Job[]; pagination: Pagination }>,
        ) => {
          state.job = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    //Handle searchTeamMembers (existing functionality)
    builder
      .addCase(searchJobs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchJobs.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Job[]; pagination: Pagination }>,
        ) => {
          state.job = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Handle create job
    builder
      .addCase(createJob.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(createJob.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.error = null; // Clear any previous errors
        state.fieldErrors = {}; // Clear field errors on success
      })
      .addCase(createJob.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.payload as unknown as string;
      });
  },
});

export const { clearJob } = jobSlice.actions;
export default jobSlice.reducer;
