// teamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusDataType } from '@/helpers/types';
import { fetchCustomerStatus } from '@/redux/thunk/customerStatusThunk';

interface CustomerStatusState {
  statusData: StatusDataType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CustomerStatusState = {
  statusData: [],
  status: 'idle',
  error: null,
};

const customerStatusSlice = createSlice({
  name: 'customer-status',
  initialState,
  reducers: {
    clearCustomers(state) {
      state.statusData = initialState.statusData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchCustomerStatus.fulfilled,
        (state, action: PayloadAction<{ data: StatusDataType[] }>) => {
          state.statusData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchCustomerStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearCustomers } = customerStatusSlice.actions;
export default customerStatusSlice.reducer;
