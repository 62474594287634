import { API_METHOD_PUT, API_PRODUCTS } from '@/constants/apiConstants';
import { ProductType } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateProduct = async (
  uuid: string,
  updatedValues: ProductType,
) => {
  const method: Method = API_METHOD_PUT;
  const url = API_PRODUCTS + '/' + uuid;
  const data = {
    item_type: updatedValues.item_type,
    name: updatedValues.name,
    description: updatedValues.description,
    cost: updatedValues.cost,
    unit_price: updatedValues.unit_price,
    image_url: updatedValues.image_url,
    is_tax_exempted: updatedValues.is_tax_exempted,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
