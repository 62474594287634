import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Team, Pagination, StatusDataType } from '@/helpers/types';
import { GetTeamMembersData } from '@/api/team/getTeamMembers';
import { SearchTeamMembersData } from '@/api/team/searchTeamMembers';
import { DeleteTeamMemberData } from '@/api/team/deleteTeamMember';
import { GetTeamMemberStatusData } from '@/api/common/getTeamMemberStatus';

// Define the type for the API response
interface TeamMemberStatusResponse {
  data: StatusDataType[];
}

// Define the type for the thunk's return value
type FetchTeamMemberStatusResponse = {
  data: StatusDataType[];
};

type FetchTeamMemberStatusError = string | SerializedError;

export const fetchTeamMemberStatus = createAsyncThunk<
  FetchTeamMemberStatusResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchTeamMemberStatusError; // Type for rejected value
  }
>('team/fetchTeamMemberStatus', async (_, { rejectWithValue }) => {
  try {
    const response = await GetTeamMemberStatusData();
    const data: TeamMemberStatusResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});
