import { API_METHOD_POST, API_PRODUCTS } from '@/constants/apiConstants';
import { ProductType } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const AddProduct = async (productValues: ProductType) => {
  const method: Method = API_METHOD_POST;
  const url = API_PRODUCTS;
  const data = {
    item_type: productValues.item_type,
    name: productValues.name,
    description: productValues.description,
    cost: productValues.cost,
    unit_price: productValues.unit_price,
    image_url: productValues.image_url,
    is_tax_exempted: productValues.is_tax_exempted,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
