import { API_ENQUIRY, API_METHOD_PUT } from '@/constants/apiConstants';
import { EnquiryType } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateEnquiry = async (
  uuid: string,
  updatedEnquiryValues: EnquiryType,
) => {
  const method: Method = API_METHOD_PUT;
  const url = API_ENQUIRY + '/' + uuid;
  const data = {
    status: updatedEnquiryValues.status,
    customer_id: updatedEnquiryValues.customer_id,
    customer_site_id: updatedEnquiryValues.customer_site_id,
    title: updatedEnquiryValues.title,
    requested_on: updatedEnquiryValues.requested_on,
    details: updatedEnquiryValues.details,
    expected_date: updatedEnquiryValues.expected_date,
    expected_time: updatedEnquiryValues.expected_time,
    onsite_assessment_required: updatedEnquiryValues.onsite_assessment_required,
    assessment: {
      details: updatedEnquiryValues.assessment.details,
      start_date: updatedEnquiryValues.assessment.start_date,
      end_date: updatedEnquiryValues.assessment.end_date,
      start_time: updatedEnquiryValues.assessment.start_time,
      end_time: updatedEnquiryValues.assessment.end_time,
      assessmentMembers: updatedEnquiryValues.assessment.assessmentMembers,
      send_notification: updatedEnquiryValues.assessment.send_notification,
    },
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
