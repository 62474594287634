import { API_INVOICE, API_METHOD_POST } from '@/constants/apiConstants';
import { InvoiceFormData } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const AddInvoice = async (newInvoiceData: InvoiceFormData) => {
  const method: Method = API_METHOD_POST;
  const url = API_INVOICE;
  const data = {
    job_id: newInvoiceData.job_id,
    customer_id: newInvoiceData.customer_id,
    invoice_number: newInvoiceData.invoice_number,
    title: newInvoiceData.title,
    issued_date: newInvoiceData.issued_date,
    payment_due: newInvoiceData.payment_due,
    customer_message: newInvoiceData.customer_message,
    tax_rate: newInvoiceData.tax_rate,
    tax_rate_id: newInvoiceData.tax_rate_id,
    discount: newInvoiceData.discount,
    notes: newInvoiceData.notes
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
