import { GetAllPaymentMethods } from '@/api/invoice/payment/getAllPaymentMethods';
import { InvoicePaymentType } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
type FetchInvoicePaymentMethodsResponse = {
  data: InvoicePaymentType[];
};

type FetchInvoiceStatusError = string | SerializedError;

// Fetch all invoice status
export const fetchAllInvoicePaymentMethods = createAsyncThunk<
  FetchInvoicePaymentMethodsResponse,
  void,
  { rejectValue: FetchInvoiceStatusError }
>(
  'invoicePayment/fetchAllInvoicePaymentMethods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await GetAllPaymentMethods();
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);
