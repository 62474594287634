import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PushNotificationCountData } from '@/helpers/types';
import { fetchPushNotificationCount } from '@/redux/thunk/pushNotificationThunk';

interface NotificationCountState {
  data: PushNotificationCountData;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: NotificationCountState = {
  data: {
    count: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const notificationCountSlice = createSlice({
  name: 'notification-count',
  initialState,
  reducers: {
    clearNotificationCount(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPushNotificationCount.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.data = initialState.data;
      })
      .addCase(
        fetchPushNotificationCount.fulfilled,
        (state, action: PayloadAction<{ data: PushNotificationCountData }>) => {
          state.data = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchPushNotificationCount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearNotificationCount } = notificationCountSlice.actions;
export default notificationCountSlice.reducer;
