import { API_METHOD_DELETE, API_TEAM_MEMBERS } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const DeleteTeamMemberData = async (uuid: string) => {
  const method: Method = API_METHOD_DELETE;
  const url = API_TEAM_MEMBERS + `${uuid}`;

  try {
    const response = await apiCall({
      method,
      url,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
