// teamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimesheetCategoryData } from '@/helpers/types';
import { fetchTimesheetCategory } from '@/redux/thunk/timesheetThunk';

interface TimesheetCategoryState {
  timesheetCategoryData: TimesheetCategoryData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: TimesheetCategoryState = {
  timesheetCategoryData: [],
  status: 'idle',
  error: null,
};

const timesheetCategorySlice = createSlice({
  name: 'timesheet-category',
  initialState,
  reducers: {
    clearCategories(state) {
      state.timesheetCategoryData = initialState.timesheetCategoryData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimesheetCategory.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchTimesheetCategory.fulfilled,
        (state, action: PayloadAction<{ data: TimesheetCategoryData[] }>) => {
          state.timesheetCategoryData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchTimesheetCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearCategories } = timesheetCategorySlice.actions;
export default timesheetCategorySlice.reducer;
