import { InvoiceNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInvoiceSingleNote } from '../thunk/invoiceNoteThunk';

interface InvoiceSingleNoteState {
  invoiceNote: InvoiceNoteType;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InvoiceSingleNoteState = {
  invoiceNote: {
    uuid: '',
    note: '',
    attachment: {
      link: '',
      size: '',
      name: '',
    },
    created_at: '',
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const invoiceSingleNoteSlice = createSlice({
  name: 'invoice-single-note-state',
  initialState,
  reducers: {
    clearInvoiceNote(state) {
      state.invoiceNote = initialState.invoiceNote;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceSingleNote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInvoiceSingleNote.fulfilled,
        (state, action: PayloadAction<InvoiceNoteType>) => {
          state.status = 'succeeded';
          state.invoiceNote = action.payload;
        },
      )
      .addCase(fetchInvoiceSingleNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearInvoiceNote } = invoiceSingleNoteSlice.actions;
export default invoiceSingleNoteSlice.reducer;
