import NodeRSA from 'node-rsa';

const encryptStringWithRSA = (key: NodeRSA, data: any) => {
  try {
    // Encrypt a string using the public key
    const encryptedData = key.encrypt(data, 'base64');

    return encryptedData;
  } catch (error) {
    console.error('Error encrypting the string:', error);
  }
};

export default encryptStringWithRSA;
