import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_METHOD_GET,
  API_PUSH_NOTIFICATION_COUNT,
} from '@/constants/apiConstants';

export const GetPushNotificationCount = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_PUSH_NOTIFICATION_COUNT;

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
