// timesheetMetricsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpensesMetricsData, TimesheetMetricsData } from '@/helpers/types';
import { fetchExpensesMetrics } from '@/redux/thunk/expensesThunk';
import { fetchTimesheetsMetrics } from '@/redux/thunk/timesheetThunk';

interface TimesheetMetricsState {
  timesheetMetrics: TimesheetMetricsData;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: TimesheetMetricsState = {
  timesheetMetrics: {
    total_timesheet: '',
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const timesheetMetricsSlice = createSlice({
  name: 'timesheet-metrics',
  initialState,
  reducers: {
    clearTimesheetMetrics(state) {
      state.timesheetMetrics = initialState.timesheetMetrics;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimesheetsMetrics.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchTimesheetsMetrics.fulfilled,
        (state, action: PayloadAction<{ data: TimesheetMetricsData }>) => {
          state.timesheetMetrics = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchTimesheetsMetrics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearTimesheetMetrics } = timesheetMetricsSlice.actions;
export default timesheetMetricsSlice.reducer;
