import { API_ENQUIRY, API_METHOD_POST } from '@/constants/apiConstants';
import { EnquiryNoteType } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const AddEnquiryNote = async (enquiryNoteValues: EnquiryNoteType) => {
  const method: Method = API_METHOD_POST;
  const url = API_ENQUIRY + '/' + enquiryNoteValues.uuid + '/notes';
  const data = {
    note: enquiryNoteValues.note,
    attachment_url: enquiryNoteValues.attachment_url,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
