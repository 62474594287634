import { InvoicePaymentType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllInvoicePaymentMethods } from '../thunk/invoicePaymentMethodsThunk';

interface InvoicePaymentMethodsState {
  invoicePaymentMethods: InvoicePaymentType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: InvoicePaymentMethodsState = {
  invoicePaymentMethods: [],
  status: 'idle',
  error: null,
};

const invoicePaymentMethodsSlice = createSlice({
  name: 'invoice-payment-methods-state',
  initialState,
  reducers: {
    clearAllInvoicePaymentMethod(state) {
      state.invoicePaymentMethods = initialState.invoicePaymentMethods;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInvoicePaymentMethods.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchAllInvoicePaymentMethods.fulfilled,
        (state, action: PayloadAction<{ data: InvoicePaymentType[] }>) => {
          state.status = 'succeeded';
          state.invoicePaymentMethods = action.payload.data;
        },
      )
      .addCase(fetchAllInvoicePaymentMethods.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearAllInvoicePaymentMethod } =
  invoicePaymentMethodsSlice.actions;
export default invoicePaymentMethodsSlice.reducer;
