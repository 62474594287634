import { JobRecurringTypeData } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetJobRecurringType } from '@/api/job/getJobRecurringType';

type FetchJobRecurringTypeResponse = {
  data: JobRecurringTypeData[];
};

type FetchJobRecurringTypeError = string | SerializedError;

// Fetch job recurring type
export const fetchJobRecurringTypes = createAsyncThunk<
  FetchJobRecurringTypeResponse,
  void,
  { rejectValue: FetchJobRecurringTypeError }
>('jobRecurringTypes/fetchJobRecurringType', async (_, { rejectWithValue }) => {
  try {
    const response = await GetJobRecurringType();
    return response;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
